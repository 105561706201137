<template>
  <v-dialog v-model="showingFormModal" persistent width="700" scrollable minHeight="1200">
    <v-form @submit.prevent="createTemplate" ref="templateForm">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ templateToEdit ? 'Editar Plantilla' : 'Crear Plantilla'}}
        </v-card-title>
        
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" class="mt-4">
              <v-text-field
              v-model="template_name"
              :rules="[v => !!v || 'Campo requerido']"
              outlined
              dense
              autocomplete="off"
              hide-details
              label="Nombre de la Plantilla"
              />
            </v-col>

            <v-col cols="12">
              <v-textarea
                :prepend-inner-icon="icons.mdiMessageBulleted"
                v-model="template_message"
                :rules="[v => !!v || 'Escriba algún mensaje']"
                counter="1000"
                maxlength="1000"
                placeholder="Mensaje"
                outlined
                id="textarea-input-template"
                dense
                hint=""
                hide-details
                label="Mensaje"
              ></v-textarea>
              <EmojiPicker @insert-emoji="insertToMessage"/>
            </v-col>

            <v-col cols="12" class="pa-0">
              <v-checkbox
                v-model="template_is_active"
                class="px-3 my-4"
                hide-details 
                label="Plantilla Activa"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
              CANCELAR
          </v-btn>
          <v-btn color="primary" type="submit">
              {{ templateToEdit ? 'Editar Plantilla' : 'Crear Plantilla'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  mdiMessageBulleted,
} from '@mdi/js'
import { props } from 'vue-prism-component'
import EmojiPicker from '@/components/Campaigns/EmojiPicker'

export default {
  data: () => {
    return {
      showingFormModal: false,
      template_message: '',
      template_name: '',
      template_is_active: false,
      templateToEdit: null,
    }
  },
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  components: {
    EmojiPicker
  },
  methods: {
    openModal(item_to_edit) {
      item_to_edit 
                ? this.setItemToEdit(item_to_edit)
                : this.showingFormModal = true
    },
    close() {
      this.$refs.templateForm.reset()
      this.template_is_active = false
      this.templateToEdit = null
      this.showingFormModal = false
    },
    async createTemplate() {
      if(this.$refs.templateForm.validate()) {
        try {
          const form = {
            name: this.template_name,
            message: this.template_message,
            is_active: this.template_is_active.toString(),
            company_id: this.companyId
          }
          const response =  this.templateToEdit
                            ? await this.$api.updateTemplate(this.templateToEdit.id, form)
                            : await this.$api.createTemplate(form)
          this.$emit('reload-template-list')
          this.close()
        } catch(e) {
          console.log(e)
        }
      }
    },
    setItemToEdit(item) {
      if(item) {
        this.templateToEdit = item
        this.template_message = item.message
        this.template_is_active = item.is_active
        this.template_name = item.name
      }
      this.showingFormModal = true
    },

    insertToMessage(item) {
      const textarea = document.getElementById('textarea-input-template')
      if(textarea.selectionStart || textarea.selectionStart == '0') {
          let startPos = textarea.selectionStart
          let endPos = textarea.selectionEnd

          textarea.value = textarea.value.substring(0, startPos)
          + `${item}`
          + textarea.value.substring(endPos, textarea.value.length)
          textarea.selectionStart = startPos + item.length
          textarea.selectionEnd = startPos + item.length
      }
      this.template_message = textarea.value
    },
  },
  setup() {
    return {
      icons: {
        mdiMessageBulleted
      }
    }
  }
}
</script>