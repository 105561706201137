<template>
  <v-dialog v-model="showingTemplatesModal" fullscreen hide-overlay>
    <v-toolbar class="tool-bar">
      <v-btn icon color="primary" @click="close()">
      <v-icon>{{icons.mdiClose}}</v-icon>
      </v-btn>
      <v-toolbar-title class="text-h5 grey lighten-2 ">Plantillas</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
      <v-divider></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          @keyup="handleSearch"
          placeholder="Buscar por nombre de la plantilla"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            v-if="getUserRole === 0 || getUserRole === 1"
            @click="$refs.template_form_modal.openModal()"
          >
				<v-icon>{{ icons.mdiPlus }}</v-icon>
				<span>Crear Plantilla</span>
				</v-btn>

			</div>
      </v-card-text>
      <v-data-table
        :headers="tableColumns"
        :items="templateListTable"
        :server-items-length="totalTemplateListTable"
        :page="current_page"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
        @pagination="changingTemplatesPage($event)"
      >

      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            No hay
            <strong>Plantillas</strong>
            disponibles para esta búsqueda
          </v-list-item-title>
        </v-list-item>
      </template>

      <template #[`item.name`]="{item}">
        <v-btn
        color="primary"
        text
        min-width="0"
        max-height="28px"
        @click="insertTemplate(item.message)"
        :disabled="!item.is_active"
        >
          <span class="font-weight-medium">{{item.name}}</span>
        </v-btn>
      </template>

      <template #[`item.message`]="{item}">
        <span >{{item.message}}</span>
      </template>

      <template #[`item.is_active`]="{item}">
        <v-btn
        class="no-uppercase status-container pa-2"
        max-height="26px"
        min-width="0"
        depressed
        :color="successColor"
        v-if="item.is_active"
        >
          <span>Activo</span>
        </v-btn>
        <v-btn
        class="no-uppercase status-container pa-2"
        max-height="26px"
        min-width="0"
        depressed
        :color="errorColor"
        v-else
        >
          <span>Inactivo</span>
        </v-btn>
      </template>

      <template #[`item.edit`]="{item}">
        <v-btn
          icon
          color="primary"
          @click="$refs.template_form_modal.openModal(item)"
          :disabled="getUserRole === 2"
        >
          <v-icon>
            {{ icons.mdiSquareEditOutline }}
          </v-icon>
        </v-btn>
      </template>

      <template #[`item.delete`]="{item}">
        <v-btn
          icon
          @click="$refs.template_delete_modal.openModal(item)"
          :disabled="getUserRole === 2"
        >
          <v-icon class="error--text">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <TemplatesFormModal ref="template_form_modal" @reload-template-list="getTemplates()" :company-id="company_id"/>
    <TemplateDeleteModal ref="template_delete_modal" @reload-template-list="getTemplates()" />
  </v-dialog>

</template>

<script>
const debounce = require('debounce');
import { ref } from '@vue/composition-api';
import TemplatesFormModal from '@/components/Templates/TemplatesFormModal'
import TemplateDeleteModal from '@/components/Templates/TemplateDeleteModal'
import {
    mdiClose,
    mdiSquareEditOutline,
    mdiDeleteOutline
} from '@mdi/js'

export default {
  data: () => {
    return {
      showingTemplatesModal: false,
      company_id: null,
      current_page: 1,
      page_size: 10,
      successColor: 'rgb(86, 202, 0)',
      errorColor: 'rgb(255, 76, 81)',
      message_key: null
    }
  },
  components: {
    TemplatesFormModal,
    TemplateDeleteModal
  },
  methods: {
    openTemplatesModal(id, k) {
      this.company_id = id
      this.message_key = k
      this.getTemplates()
      this.showingTemplatesModal = true
    },
    close() {
      this.showingTemplatesModal = false
    },
    async getTemplates() {
      this.loading = true
      try {
        const data = {
          page: this.current_page,
          page_size: this.page_size,
          company_id: this.company_id,
          search: this.searchQuery
        }
        const response = await this.$api.getTemplatesList(data)
        this.templateListTable = response.results
        this.totalTemplateListTable = response.count
        this.loading = false
      } catch(e) {
        console.log(e)
      }
    },

    changingTemplatesPage(e) {
      if (e.pageStop !== 0) {
        this.current_page = e.page
        this.page_size = e.itemsPerPage
        this.getTemplates()
      }
    },
    handleSearch: debounce(function (e) {
      this.current_page = 1
      this.getTemplates()
    }, 400),

    insertTemplate(message) {
      if(this.message_key || this.message_key === 0) {
        let form = {
          text: message,
          key: this.message_key
        }
        this.$emit('insert-template', form)
      } else {
        this.$emit('insert-template', message)
      }
      this.close()
    }
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  setup() {
    const totalTemplateListTable = ref(1);
    const templateListTable = ref([]);

    const tableColumns = [
      { text: 'Nombre', value: 'name', align: 'center', sortable: false },
      { text: 'Mensaje', value: 'message', align: 'center', sortable: false },
      { text: 'Estado', value: 'is_active', align: 'center', sortable: false },
      { text: 'Editar', value: 'edit', align: 'center', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false },
    ];

    const loading = ref(false);
    const searchQuery = ref('');
    return {
      loading,
      tableColumns,
      templateListTable,
      totalTemplateListTable,
      searchQuery,
      icons: {
        mdiClose,
        mdiSquareEditOutline,
        mdiDeleteOutline
      }
    }
  }
}
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
.status-container {
  color: white !important;
  font-size: 13px;
}
</style>
