<template>
  <v-dialog v-model="showingDeleteModal" width="auto">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        ¿Estás seguro de eliminar la plantilla '{{ template.name }}'?
      </v-card-title>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn 
          color="primary" 
          text 
          @click="close()"
          >
            CANCELAR
          </v-btn>         
          <v-btn
          color="error"
          @click="deleteTemplate()"
          >
            Eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => {
    return {
      showingDeleteModal: false,
      template: {},
    }
  },
  methods: {
    openModal(item) {
      this.template = item
      this.showingDeleteModal = true
    },
    close() {
      this.template = {}
      this.showingDeleteModal = false
    },
    async deleteTemplate() {
      try {
        const response = await this.$api.deleteTemplate(this.template.id)
        this.$emit('reload-template-list')
        this.close()
      } catch(e) {
        console.log(e)
      }
    }
  }
}

</script>