import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":""},model:{value:(_vm.showingTemplatesModal),callback:function ($$v) {_vm.showingTemplatesModal=$$v},expression:"showingTemplatesModal"}},[_c(VToolbar,{staticClass:"tool-bar"},[_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiClose))])],1),_c(VToolbarTitle,{staticClass:"text-h5 grey lighten-2 "},[_vm._v("Plantillas")]),_c(VSpacer)],1),_c(VCard,[_c(VDivider),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar por nombre de la plantilla","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.getUserRole === 0 || _vm.getUserRole === 1)?_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.template_form_modal.openModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Crear Plantilla")])],1):_vm._e()],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.templateListTable,"server-items-length":_vm.totalTemplateListTable,"page":_vm.current_page,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"pagination":function($event){return _vm.changingTemplatesPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Plantillas")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"color":"primary","text":"","min-width":"0","max-height":"28px","disabled":!item.is_active},on:{"click":function($event){return _vm.insertTemplate(item.message)}}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.message))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c(VBtn,{staticClass:"no-uppercase status-container pa-2",attrs:{"max-height":"26px","min-width":"0","depressed":"","color":_vm.successColor}},[_c('span',[_vm._v("Activo")])]):_c(VBtn,{staticClass:"no-uppercase status-container pa-2",attrs:{"max-height":"26px","min-width":"0","depressed":"","color":_vm.errorColor}},[_c('span',[_vm._v("Inactivo")])])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"primary","disabled":_vm.getUserRole === 2},on:{"click":function($event){return _vm.$refs.template_form_modal.openModal(item)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","disabled":_vm.getUserRole === 2},on:{"click":function($event){return _vm.$refs.template_delete_modal.openModal(item)}}},[_c(VIcon,{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)})],1),_c('TemplatesFormModal',{ref:"template_form_modal",attrs:{"company-id":_vm.company_id},on:{"reload-template-list":function($event){return _vm.getTemplates()}}}),_c('TemplateDeleteModal',{ref:"template_delete_modal",on:{"reload-template-list":function($event){return _vm.getTemplates()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }